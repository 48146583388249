$backgroundColour: #f7f7f7;
$headerColour: #202943;
$headerHeight: 70px;
$statsHeight: 216px;
$footerHeight: 50px;
$borderColour: #c2bebe;
$dropShadow: lighten($borderColour, 10);
$inactiveColour: #f3f3f3;
$feedbackColour: #c42a2a;
$primary: #2bb5a2;
$primaryDark: darken($primary, 5);
$primaryLight: lighten($primary, 20);
$footerColour: darken($backgroundColour, 15);
$secondary: #5887da;

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-color: $backgroundColour;
}

.Header {
  height: $headerHeight;
  width: 100vw;
  max-width: 100vw;
  background-color: $headerColour;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.HeaderLogo {
  margin: 12px;
}

.Content {
  display: flex;
  flex-direction: column;
  align-items: center;

  min-height: calc(
    100vh - #{$footerHeight} - #{$headerHeight} - #{$statsHeight}
  );

  margin: 0 15px;
}

.Footer {
  color: $footerColour;

  height: $footerHeight;
  width: 100vw;

  text-align: center;
}

.Card {
  background-color: #ffffff;

  padding: 20px;

  border-radius: 15px;
  box-shadow: 2px 2px $dropShadow;
}

.CardContainer {
  background-color: #ffffff;
}

.TabSelect {
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

.Tab {
  flex: 1;
  height: 45px;

  font-size: 12pt;

  border: 0;
  border-bottom: 1px solid $borderColour;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  background-color: $inactiveColour;
  outline: none;
}

.ActiveTab {
  background-color: #ffffff;
  border: 0;

  border-top: 1px solid $borderColour;
  border-left: 1px solid $borderColour;
  border-right: 1px solid $borderColour;
}

.InputForm {
  display: flex;
  flex-direction: column;
}

.InputLabel {
  margin-top: 25px;
  margin-left: 5px;
}

.TextContainer {
  max-width: 450px;

  display: flex;
  align-items: center;
  flex-direction: column;

  text-align: center;
}

.Input {
  outline: none;
  height: 38px;
  font-size: 14pt;
  margin-top: 10px;
  min-width: 500px;
  padding: 5px;
  border: 1px solid $borderColour;
  border-radius: 5px;
}

.FeedbackWarning {
  color: $feedbackColour;
  margin: 5px 0 0 0;
}

.Input:read-only {
  background-color: $inactiveColour;
}

.SubmitButton {
  border: 0;
  border-radius: 5px;
  margin-top: 30px;
  height: 50px;
  font-size: 14pt;
  background-color: $primary;
  color: white;
  outline: none;
}

.SubmitButton:active {
  background-color: $primaryDark;
}

.SubmitButton:disabled {
  background-color: #82e0d0;
}

.ButtonGroup {
  display: flex;
}

.StatusButton {
  border: 0;
  border-radius: 5px;
  margin-top: 30px;
  height: 50px;
  font-size: 14pt;
  background-color: $secondary;
  color: white;
  outline: none;
}

.ConnectButton {
  @extend .StatusButton;

  flex: 1;
  margin-left: 4px;
  margin-right: 4px;
}

.ConnectButton:first-of-type {
  margin-left: 0;
}

.ConnectButton:last-of-type {
  margin-right: 0;
}

.StatusButton:disabled {
  background-color: lighten($secondary, 10%);
}

.FailureButton {
  border: 0;
  border-radius: 5px;
  margin-top: 30px;
  height: 50px;
  font-size: 14pt;
  background-color: $feedbackColour;
  color: white;
  outline: none;
}

.ProgressIconTray {
  display: flex;
  margin-top: 20px;
  justify-content: center;
}

.CardHeading {
  text-align: center;
}

.ComingSoonText {
  width: 512px;

  display: flex;
  align-items: center;
  flex-direction: column;

  text-align: center;
}

.ComingSoonText p {
  line-height: 18pt;
}

.StatsWrapper {
  text-align: center;
  height: $statsHeight;
}

.StatsContainer {
  margin: 0 15px 15px 15px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.StatsCard {
  @extend .Card;

  border-radius: 8px;
  margin: 15px;

  width: 180px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.StatsName {
  font-weight: bold;
  margin-bottom: 15px;

  text-align: center;
}

.modal {
  min-height: 100vh;
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  z-index: 20;
}

.modal-bottom {
  align-items: flex-end;
}

.modal-top {
  align-items: flex-start;
}

.modal-center {
  align-items: center;
}

.content {
  display: flex;
  flex-direction: column;
  border: 1px solid #d1d5db;
  border-radius: 0.375rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  margin-bottom: 2.5rem;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  font-size: 20px;
  margin: 0.5rem;
}

.overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(31, 41, 55, 0.75);
  z-index: 9100;
}

.close-button {
  padding: 0.75rem;
  border-radius: 0.375rem;
  cursor: pointer;
}

.card-container {
  margin-left: auto;
  margin-right: auto;
  background-color: #ffffff;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  overflow: hidden;
}

.wallet-container {
  width: 95%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
}

.wallet-card {
  border: 1px solid #d1d5db;
  display: flex;
  align-items: center;
  padding: 1rem;
  margin-top: 10px;
  margin-bottom: 10px;
}

.connect-button {
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  background-color: $secondary;
  color: white;
  cursor: pointer;
  margin-left: auto;
  border: none;
}

.connect-button:disabled {
  background-color: #9ca3af;
  cursor: not-allowed;
}

.spinner-container {
  display: flex;
  gap: 0.25rem;
}

.wallet-icon {
  width: 30px;
  height: 30px;
  margin-right: 15px;
}

.wallet-icon > img {
  width: inherit;
}

.wallet-connect-modal {
  width: 400px;
  padding: 10px;
}
